import { useEffect } from 'react';
import { useLocation } from 'react-router';

const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    props.finished(false);
    scrollPromise().then(() => props.finished(true));
  }, [location]);

  const scrollPromise = () => {
    window.scrollTo(0, 0);
    return new Promise((resolve) => {resolve()});
  };

  return <>{props.children}</>;
};

export default ScrollToTop;
