import '../scss/SliderSmall.scss';
import 'swiper/css';
import 'swiper/css/navigation';

import React from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const SliderSmall = (props) => {
  const { children, data } = props;

  return (
    <section className="slider-small">
      {children}
      <div className="slider-wrapper">
        <Swiper
          modules={[Navigation]}
          loop={true}
          slidesPerView={1}
          spaceBetween={15}
          navigation
          breakpoints={{
            576: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
            700: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            1000: {
              slidesPerView: 2.5,
              spaceBetween: 30,
            },
            1440: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1800: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
          }}>
          {data.map((slide, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="slider-inner">
                  <svg width="57" height="55" viewBox="0 0 57 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M57 0V8.98406C54.2547 9.56839 51.943 10.9562 50.0646 13.1474C48.3308 15.3387 47.0304 18.1142 46.1635 21.4741C45.4411 24.6879 45.0798 28.2669 45.0798 32.2112H55.0494V55H33.8099V34.6215C33.8099 26.1487 34.9658 19.575 37.2776 14.9004C39.7338 10.0797 42.7681 6.57371 46.3802 4.38248C49.9924 2.04516 53.5323 0.58433 57 0ZM23.4068 0V8.98406C20.6616 9.56839 18.3498 10.9562 16.4715 13.1474C14.7376 15.3387 13.4373 18.1142 12.5703 21.4741C11.8479 24.6879 11.4867 28.2669 11.4867 32.2112H21.2395V55H0V34.6215C0 26.1487 1.22814 19.575 3.68441 14.9004C6.14068 10.0797 9.1749 6.57371 12.7871 4.38248C16.3992 2.04516 19.9392 0.58433 23.4068 0Z"
                      fill="#1A549E"
                    />
                  </svg>
                  <p>{slide.text}</p>
                  <div>
                    <div className="line"></div>
                    <p>
                      {slide.name} <br />
                      <span>{slide.job}</span>
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
};

export default SliderSmall;
