import React from 'react';
import { useTranslation } from 'react-i18next';
import Hero from '../components/Hero';
import FullWidthTeaser from '../components/FullWidthTeaser';
import Charts from '../components/Charts';
import ContactTeaser from '../components/ContactTeaser';
import YoutubeEmbed from '../components/YoutubeEmbed';

import automation from '../assets/images/automation.jpg';
import industrial from '../assets/images/industrial-2.jpg';
import ecar from '../assets/icons/ecar.svg';
import cars from '../assets/icons/cars.svg';
import leaf from '../assets/icons/leaf.svg';
import microscope from '../assets/icons/microscope.svg';
import roboter from '../assets/icons/roboter.svg';
import board from '../assets/icons/board.svg';
import { fadeInXAxis, fadeInYAxis } from '../utils/animation.utils';

const Automation = () => {
  const { t } = useTranslation('common');

  const introText = React.useRef();
  const line = React.useRef();
  const serviceList = React.useRef();
  const serviceTitle = React.useRef();

  React.useLayoutEffect(() => {
    fadeInYAxis(introText, '.intro', '200%');
    fadeInYAxis(line, '.blue-text', '200%', 0.5);
    fadeInXAxis(serviceTitle, '-200%');
    fadeInXAxis(serviceList, '200%');
  });

  const chartData = [
    {
      number: '100<sup>%</sup>',
      text: t('automation.chart-text1'),
      datasets: [
        {
          data: [100],
          backgroundColor: ['#F5F5F5'],
          borderWidth: 0,
          cutout: '90%',
        },
      ],
    },
    {
      number: '100<sup>%</sup>',
      text: t('automation.chart-text2'),
      datasets: [
        {
          data: [100],
          backgroundColor: ['#F5F5F5'],
          borderWidth: 0,
          cutout: '90%',
        },
      ],
    },
    {
      number: '100<sup>%</sup>',
      text: t('automation.chart-text3'),
      datasets: [
        {
          data: [100],
          backgroundColor: ['#F5F5F5'],
          borderWidth: 0,
          cutout: '90%',
        },
      ],
    },
  ];

  return (
    <main>
      <Hero>
        <h1 dangerouslySetInnerHTML={{ __html: t('automation.title') }}></h1>
        <h2>{t('automation.sub')}</h2>
      </Hero>
      <section className="wrapped intro">
        <div ref={introText}>
          <h2 className="mb-18 uppercase" dangerouslySetInnerHTML={{ __html: t('automation.intro.title') }}></h2>
          <p className="justify">{t('automation.intro.text')}</p>
        </div>
      </section>
      <FullWidthTeaser img={automation} borderBottom={false} big={true}>
        <h2 className="uppercase mb-18" dangerouslySetInnerHTML={{ __html: t('automation.fw-teaser.title') }}></h2>
        <p className="justify">{t('automation.fw-teaser.text')}</p>
        <div className="grid-cards">
          <div className="card">
            <img src={ecar} alt="ecar" />
            <p>{t('automation.fw-teaser.card1')}</p>
          </div>
          <div className="card">
            <img src={leaf} alt="leaf" />
            <p>{t('automation.fw-teaser.card2')}</p>
          </div>
          <div className="card">
            <img src={microscope} alt="microscope" />
            <p>{t('automation.fw-teaser.card3')}</p>
          </div>
          <div className="card">
            <img src={board} alt="board" />
            <p>{t('automation.fw-teaser.card4')}</p>
          </div>
          <div className="card">
            <img src={cars} alt="cars" />
            <p>{t('automation.fw-teaser.card5')}</p>
          </div>
          <div className="card">
            <img src={roboter} alt="roboter" />
            <p>{t('automation.fw-teaser.card6')}</p>
          </div>
        </div>
      </FullWidthTeaser>
      <section className="wrapped">
        <img className="w-100 mood-img" src={industrial} alt="industrial" />
      </section>
      <section className="blue-text">
        <div className="wrapped flex home-text justify-center">
          <h2 className="uppercase align-self-center" ref={serviceTitle}>
            {t('automation.service.title')}
          </h2>
          <div className="line" ref={line}></div>
          <ul className="w-50 w-mb-100 real-list" ref={serviceList}>
            <li>{t('automation.service.list1')}</li>
            <li>{t('automation.service.list2')}</li>
            <li>{t('automation.service.list3')}</li>
            <li>{t('automation.service.list4')}</li>
            <li>{t('automation.service.list5')}</li>
            <li>{t('automation.service.list6')}</li>
          </ul>
        </div>
      </section>
      <Charts data={chartData} />
      <YoutubeEmbed embedId="xFfwR-ltNL0">
        <h2 className='mb-18'>{t('automation.video-title')}</h2>
        <p>{t('automation.video-text')}</p>
      </YoutubeEmbed>
      <ContactTeaser type={'small'} />
    </main>
  );
};

export default Automation;
