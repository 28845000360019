import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Autoplay } from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

const SliderLogos = (props) => {
  const { logos, children } = props;
  return (
    <section>
      {children}
      <div className="logo-teaser">
        <Swiper
          modules={[Navigation, Autoplay]}
          slidesPerView={1}
          loop={true}
          navigation
          autoplay={{ delay: 2000 }}
          breakpoints={{
            576: {
              slidesPerView: 2,
              spaceBetween: 50,
            },
            800: {
              slidesPerView: 3,
              spaceBetween: 0,
            },
            1100: {
              slidesPerView: 4,
            },
            1440: {
              slidesPerView: 5,
            },
          }}>
          {logos.map((logo, i) => (
            <SwiperSlide key={i}>
              <img className="logo-box" src={logo} alt={logo} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default SliderLogos;
