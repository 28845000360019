import React from 'react';

const Imprint = () => {
  return (
    <main className="imprint">
      <section className="wrapped">
        <h1 className="mb-32">Imprint</h1>
        <p>
          Anschrift:
          <br />
          EW-Tec Industrieservice & Anlagentechnik GmbH
          <br />
          Lötsch 89
          <br />
          D-41334 Nettetal
          <br />
          <br />
          Kontakt:
          <br />
          Telefon: 02153 95294100
          <br />
          E-Mail: info@ew-tec.com
          <br />
          Amtsgericht Krefeld, HRB8585
          <br />
          Geschäftsführer: Nick Neubert
          <br />
          Umsatzsteuer-Ident-Nummer: DE 218385749
        </p>
        <h2>Haftungsausschuss und Hinweise zum Urheberrecht</h2>
        <h3>Haftungsausschluss</h3>
        <p>
          1. Inhalt des Onlineangebotes
          <br />
          Die EW-Tec Industrieservice & Anlagentechnik GmbH übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität
          der bereitgestellten Informationen. Haftungsansprüche gegen EW-Tec Industrieservice & Anlagentechnik GmbH, die sich auf Schäden materieller
          oder ideeller Art beziehen, welche durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter
          und unvollständiger Informationen verursacht wurden sind grundsätzlich ausgeschlossen, sofern seitens von der EW-Tec Industrieservice &
          Anlagentechnik GmbH kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt.
          <br />
          Alle Angebote sind freibleibend und unverbindlich. Die EW-Tec Industrieservice & Anlagentechnik GmbH behält es sich ausdrücklich vor, Teile
          der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise
          oder endgültig einzustellen.
        </p>
        <p>
          2. Verweise und Links
          <br />
          Die EW-Tec Industrieservice & Anlagentechnik GmbH stellt auf dieser Website auch Links zu Fremdangeboten zur Verfügung. Für Inhalte und
          insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener Informationen entstehen, haftet allein der Anbieter
          dieser Seiten. Die EW-Tec Industrieservice & Anlagentechnik GmbH überprüft Seiten lediglich zum Zeitpunkt der Linksetzung. Alle späteren
          Veränderungen unterliegen der Verantwortung des Anbieters.
        </p>
        <p>
          3. Rechtswirksamkeit dieses Haftungsausschlusses
          <br />
          Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder
          einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die
          übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.
        </p>
        <p>
          Urheberrecht
          <br />
          Copyright 2022 EW-Tec Industrieservice & Anlagentechnik GmbH. Alle Rechte vorbehalten. Sämtliche Bilder, Graphiken, Texte, Ton-, Video- und
          Animationsdateien dieser Website unterliegen dem Urheberrecht bzw. anderen Gesetzen zum Schutz geistigen Eigentums. Eine Vervielfältigung,
          Veränderung oder Verwendung in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung der EW-Tec
          Industrieservice & Anlagentechnik GmbH nicht gestattet.
        </p>
      </section>
    </main>
  );
};

export default Imprint;
