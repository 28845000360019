import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";

import logo from "../assets/icons/EW-Tec-Logo.svg";

const Nav = () => {
  const [scrolled, setScrolled] = useState(false);
  const [toggled, setToggled] = useState(false);
  const { t } = useTranslation("common");

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const handleToggle = () => {
    setToggled(!toggled);
  };

  const handleToggleOff = () => {
    setToggled(false);
  };

  const handleLanguage = (e) => {
    if (!e) {
      i18n.changeLanguage("de");
    } else {
      i18n.changeLanguage("en");
    }
  };

  const toggleClass = toggled ? "toggled" : "";
  const scrolledClass = scrolled ? "scrolled" : "";

  window.addEventListener("scroll", handleScroll);

  return (
    <nav className={`${toggleClass} ${scrolledClass}`}>
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="ew-tec-logo" />
        </Link>
      </div>
      <div className={`toggler hide-desktop ${toggleClass}`} onClick={handleToggle}></div>
      <ul>
        <li>
          <NavLink
            to="/"
            onClick={handleToggleOff}
            className={({ isActive }) => (isActive ? "active nav-link" : "nav-link")}>
            {t("nav.nav1")}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="automation"
            onClick={handleToggleOff}
            className={({ isActive }) => (isActive ? "active nav-link" : "nav-link")}>
            {t("nav.nav2")}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="industrie4.0"
            onClick={handleToggleOff}
            className={({ isActive }) => (isActive ? "active nav-link" : "nav-link")}>
            {t("nav.nav3")}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="retroFit"
            onClick={handleToggleOff}
            className={({ isActive }) => (isActive ? "active nav-link" : "nav-link")}>
            {t("nav.nav4")}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="team"
            onClick={handleToggleOff}
            className={({ isActive }) => (isActive ? "active nav-link" : "nav-link")}>
            {t("nav.nav5")}
          </NavLink>
        </li>
        <li>
		<NavLink
            to="career"
            onClick={handleToggleOff}
            className={({ isActive }) => (isActive ? "active nav-link" : "nav-link")}>
            {t("nav.nav6")}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="contact"
            onClick={handleToggleOff}
            className={({ isActive }) => (isActive ? "active nav-link" : "nav-link")}>
            {t("nav.nav7")}
          </NavLink>
        </li>
        <li>
          <div className="switch-button">
            <input
              onChange={(e) => handleLanguage(e.target.checked)}
              className="switch-button-checkbox"
              type="checkbox"></input>
            <label className="switch-button-label" htmlFor="">
              <span className="switch-button-label-span">en</span>
            </label>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
