import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);

export const fadeInXAxis = (elRef, xAxis, delay = 0, positionTrigger = 'center', pagePosition = 'bottom') => {
  gsap.from(elRef.current, {
    x: xAxis,
    opacity: '0',
    delay: delay,
    duration: 0.5,
    scrollTrigger: { trigger: elRef.current, start: `${positionTrigger} ${pagePosition}` },
    ease: 'sine.out',
  });
};

export const fadeInYAxis = (elRef, trigger, yAxis, delay = 0, positionTrigger = 'top', pagePosition = 'bottom') => {
  gsap.from(elRef.current, {
    y: yAxis,
    opacity: '0',
    delay: delay,
    duration: 0.5,
    scrollTrigger: { trigger: trigger, start: `${positionTrigger} ${pagePosition}` },
    ease: 'sine.out',
  });
};

export const staggerInYAxis = (classNames, trigger, yAxis, positionTrigger = 'center', pagePosition = 'bottom') => {
  gsap.from(classNames, {
    y: yAxis,
    opacity: '0',
    stagger: 0.25,
    duration: 0.5,
    scrollTrigger: { trigger: trigger, start: `${positionTrigger} ${pagePosition}` },
    ease: 'sine.out',
  });
};

export const fadeIn = (elRef, trigger, delay = 0, positionTrigger = 'center', pagePosition = 'bottom') => {
  gsap.from(elRef.current, {
    opacity: '0',
    delay: delay,
    duration: 0.6,
    scrollTrigger: { trigger: trigger.current, start: `${positionTrigger} ${pagePosition}` },
    ease: CustomEase.create('custom', 'M0,0,C0,0,0.386,-0.027,0.66,0.246,0.88,0.466,1,1,1,1'),
  });
};
