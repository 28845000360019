import React from "react";
import "../scss/ContactTeaser.scss";
import mail from "../assets/images/mail.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fadeInXAxis } from '../utils/animation.utils';


const ContactTeaser = (props) => {
  const { type } = props;
  const { t } = useTranslation("common");

  const leftRef = React.useRef();
  const rightRef = React.useRef();

  React.useLayoutEffect(() => {
    fadeInXAxis(leftRef, '-200');
    fadeInXAxis(rightRef, '200');
  });

  if (type === "small") {
    return (
      <section className="contact-teaser wrapped small">
        <div className="flex">
          <img src={mail} alt="mail" ref={leftRef} />
          <div ref={rightRef}>
            <p>{t("contact-teaser.text-small")}</p>
            <Link to="/contact" className="btn">
              {t("contact-teaser.btn-small")}
            </Link>
          </div>
        </div>
      </section>
    );
  }
  if (type === "big") {
    return (
      <section className="contact-teaser big">
        <div className="wrapped flex">
          <img src={mail} alt="mail" ref={leftRef} />
          <div ref={rightRef}>
            <p>{t("contact-teaser.text")}</p>
            <Link to="/contact" className="btn">
              {t("contact-teaser.btn")}
            </Link>
          </div>
        </div>
      </section>
    );
  }

  if (type === "job") {
    return (
      <section className="contact-teaser big job">
        <div className="wrapped flex">
          <img src={mail} alt="nail" ref={leftRef}/>
          <div ref={rightRef}>
            <p>{t("contact-teaser.text-job")}</p>
            <a href={`mailto:${process.env.REACT_APP_MAIL_TO}?&subject=Bewerbung`} className="btn">
              {t("contact-teaser.btn-job")}
            </a>
          </div>
        </div>
      </section>
    );
  }
};

export default ContactTeaser;
