import '../scss/Team.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import Charts from '../components/Charts';
import ContactTeaser from '../components/ContactTeaser';
import Hero from '../components/Hero';
import SliderSmall from '../components/SliderSmall';
import { fadeInYAxis } from '../utils/animation.utils';
import nickNeubert from '../assets/images/nick-neubert.jpg';

const Team = () => {
  const { t } = useTranslation('common');

  const introText = React.useRef();
  const teamText = React.useRef();
  const imgText = React.useRef();

  React.useLayoutEffect(() => {
    fadeInYAxis(introText, '.intro', '200%');
    fadeInYAxis(teamText, '.slider-small', '200%', 0.4);
    fadeInYAxis(imgText, '.img-quote', '200%', 0.2, 'top', 'center');
  });

  const chartData = [
    {
      number: `38,8<sub>${t('team.chart-sub-text')}</sub>`,
      text: t('team.chart-text1'),
      datasets: [
        {
          data: [100],
          backgroundColor: ['#8BA2C1'],
          borderWidth: 0,
          cutout: '90%',
        },
      ],
    },
    {
      number: `9,5<sub>${t('team.chart-sub-text')}</sub>`,
      text: t('team.chart-text2'),
      datasets: [
        {
          data: [100],
          backgroundColor: ['#8BA2C1'],
          borderWidth: 0,
          cutout: '90%',
        },
      ],
    },
    {
      number: `426<sub>${t('team.chart-sub-text')}</sub>`,
      text: t('team.chart-text3'),
      datasets: [
        {
          data: [100],
          backgroundColor: ['#8BA2C1'],
          borderWidth: 0,
          cutout: '90%',
        },
      ],
    },
  ];

  const sliderData = [
    {
      text: t('team.slider.slide1.text'),
      name: t('team.slider.slide1.name'),
      job: t('team.slider.slide1.job'),
    },
    {
      text: t('team.slider.slide2.text'),
      name: t('team.slider.slide2.name'),
      job: t('team.slider.slide2.job'),
    },
    {
      text: t('team.slider.slide3.text'),
      name: t('team.slider.slide3.name'),
      job: t('team.slider.slide3.job'),
    },
    {
      text: t('team.slider.slide4.text'),
      name: t('team.slider.slide4.name'),
      job: t('team.slider.slide4.job'),
    },
    {
      text: t('team.slider.slide5.text'),
      name: t('team.slider.slide5.name'),
      job: t('team.slider.slide5.job'),
    },
    {
      text: t('team.slider.slide6.text'),
      name: t('team.slider.slide6.name'),
      job: t('team.slider.slide6.job'),
    },
    {
      text: t('team.slider.slide7.text'),
      name: t('team.slider.slide7.name'),
      job: t('team.slider.slide7.job'),
    },
    {
      text: t('team.slider.slide8.text'),
      name: t('team.slider.slide8.name'),
      job: t('team.slider.slide8.job'),
    },
    {
      text: t('team.slider.slide9.text'),
      name: t('team.slider.slide9.name'),
      job: t('team.slider.slide9.job'),
    },
    {
      text: t('team.slider.slide10.text'),
      name: t('team.slider.slide10.name'),
      job: t('team.slider.slide10.job'),
    },
    {
      text: t('team.slider.slide11.text'),
      name: t('team.slider.slide11.name'),
      job: t('team.slider.slide11.job'),
    },
    {
      text: t('team.slider.slide12.text'),
      name: t('team.slider.slide12.name'),
      job: t('team.slider.slide12.job'),
    },
    {
      text: t('team.slider.slide13.text'),
      name: t('team.slider.slide13.name'),
      job: t('team.slider.slide13.job'),
    },
    {
      text: t('team.slider.slide14.text'),
      name: t('team.slider.slide14.name'),
      job: t('team.slider.slide14.job'),
    },
  ];

  return (
    <main>
      <Hero>
        <h1 dangerouslySetInnerHTML={{ __html: t('team.title') }}></h1>
        <h2>{t('team.sub')}</h2>
      </Hero>
      <section className="wrapped intro">
        <div ref={introText}>
          <h2 className="mb-18 uppercase" dangerouslySetInnerHTML={{ __html: t('team.intro.title') }}></h2>
          <p className="mb-32 justify">{t('team.intro.text1')}</p>
          <p className="justify">{t('team.intro.text2')}</p>
        </div>
      </section>
      <Charts data={chartData} />
      <section className="wrapped img-quote">
        <img src={nickNeubert} alt="ceo" className="w-100 mb-32" />
        <div ref={imgText}>
          <p className="mb-54 py-100" dangerouslySetInnerHTML={{ __html: t('team.img-text') }}></p>
          <p className="text-center">{t('team.img-quote')}</p>
        </div>
      </section>
      {/* <ContactTeaser type={'job'} /> */}
      <SliderSmall data={sliderData}>
        <div className="wrapped text-center mb-84" ref={teamText}>
          <h2 className="mb-18">{t('team.slider.title')}</h2>
          <p>{t('team.slider.sub')}</p>
        </div>
      </SliderSmall>
      <ContactTeaser type={'small'} />
    </main>
  );
};

export default Team;
