import React from 'react';
import { useTranslation } from 'react-i18next';
import crazy from '../assets/images/crazy.jpg';
import Charts from '../components/Charts';
import ContactTeaser from '../components/ContactTeaser';
import Hero from '../components/Hero';
import { fadeInXAxis, fadeInYAxis } from '../utils/animation.utils';

const Industrie = () => {
  const { t } = useTranslation('common');

  const introText = React.useRef();
  const line = React.useRef();
  const serviceList = React.useRef();
  const serviceTitle = React.useRef();

  React.useLayoutEffect(() => {
    fadeInYAxis(introText, '.intro', '200%');
    fadeInYAxis(line, '.blue-text', '200%');
    fadeInXAxis(serviceTitle, '-200%');
    fadeInXAxis(serviceList, '200%');
  });

  const chartData = [
    {
      number: '100<sup>%</sup>',
      text: t('industrie.chart-text1'),
      datasets: [
        {
          data: [100],
          backgroundColor: ['#F5F5F5'],
          borderWidth: 0,
          cutout: '90%',
        },
      ],
    },
    {
      number: '100<sup>%</sup>',
      text: t('industrie.chart-text2'),
      datasets: [
        {
          data: [100],
          backgroundColor: ['#F5F5F5'],
          borderWidth: 0,
          cutout: '90%',
        },
      ],
    },
    {
      number: '100<sup>%</sup>',
      text: t('industrie.chart-text3'),
      datasets: [
        {
          data: [100],
          backgroundColor: ['#F5F5F5'],
          borderWidth: 0,
          cutout: '90%',
        },
      ],
    },
  ];

  return (
    <main>
      <Hero>
        <h1 dangerouslySetInnerHTML={{ __html: t('industrie.title') }}></h1>
        <h2 dangerouslySetInnerHTML={{ __html: t('industrie.sub') }}></h2>
      </Hero>
      <section className="wrapped intro">
       <div ref={introText}>
          <h2 className="mb-18 uppercase" dangerouslySetInnerHTML={{ __html: t('industrie.intro.title') }}></h2>
          <p className="mb-32 justify">{t('industrie.intro.text1')}</p>
          <p className="justify">{t('industrie.intro.text2')}</p>
       </div>
      </section>
      <section className="blue-text">
        <div className="wrapped flex home-text justify-center">
          <h2 className="uppercase align-self-center" ref={serviceTitle}>
            {t('industrie.service.title')}
          </h2>
          <div className="line" ref={line}></div>
          <ul className="w-50 w-mb-100 real-list" ref={serviceList}>
            <li>{t('industrie.service.list1')}</li>
            <li>{t('industrie.service.list2')}</li>
            <li>{t('industrie.service.list3')}</li>
            <li>{t('industrie.service.list4')}</li>
            <li>{t('industrie.service.list5')}</li>
            <li>{t('industrie.service.list6')}</li>
            <li>{t('industrie.service.list7')}</li>
            <li>{t('industrie.service.list8')}</li>
          </ul>
        </div>
      </section>
      <section className="wrapped">
        <img className="w-100 mood-img" src={crazy} alt="future" />
      </section>
      <Charts data={chartData} />
      <ContactTeaser type={'small'} />
    </main>
  );
};

export default Industrie;
