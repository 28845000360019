import React from 'react';
import '../scss/Charts.scss';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js';
import { staggerInYAxis } from '../utils/animation.utils';

Chart.register(ArcElement);

const Charts = (props) => {
  const { data } = props;

  React.useLayoutEffect(() => {
    staggerInYAxis('.chart', '.charts', '200%');
  });

  return (
    <section className="charts wrapped">
      <div className="charts-wrapper">
        {data.map((chart, index) => {
          return (
            <div className="chart" key={index}>
              <div className="chart-content">
                <div className="chart-wrapper">
                  <Doughnut data={chart} key={index} />
                </div>
                <p className="chart-label" dangerouslySetInnerHTML={{ __html: chart.number }} />
              </div>
              <p className="text-center text" dangerouslySetInnerHTML={{ __html: chart.text }} />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Charts;
